/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { useSpring, animated } from 'react-spring'

import "../styles/index.sass";

const calc = (x, y) => [-(y - 260 / 2) / 20, (x - 260 / 2) / 20, 1]
const trans = (x, y, s) => `perspective(260px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const TemplateWrapper = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 180, friction: 12 } }))
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            introTextNode {
              childMarkdownRemark {
                html
              }
            }
            copyright
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
        }
      `}
      render={data => (
        <div className={`container ${showMenu ? "is-open" : ""}`}>
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
          />
          <div className="container__sidebar">
            <div className="sidebar">
              <Link to="/">
                <animated.svg className="logo" onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })} width='100%' height='100%' viewBox="0 0 100 100" style={{ transform: props.xys.interpolate(trans) }}>
                  <path
                    fill="#2ab6ea"
                    className="path1"
                    d="M87.74 27.39v-.02L50 5.58 12.26 27.37v.02L50 49.18z"
                  />
                  <path
                    fill="#265caa"
                    className="path2"
                    d="M49.03 66.01L30.84 55.5l-.04-2.23 18.23 10.52V50.87l-37.74-21.8v43.56l37.74 21.79V80.98l-18.19-10.5-.03-2.24 18.22 10.52z"
                  />
                  <path
                    fill="#253f7e"
                    className="path3"
                    d="M50.97 50.87v43.55l13.2-7.62V66.42l1.92-1.11v20.38l11.04-6.37V58.94l1.92-1.11v20.38l9.66-5.58V29.07z"
                  />
                  <path fill="none" d="M0 0h100v100H0z" />
                </animated.svg>
              </Link>
              <h6 className="sidebar__title">
                <Link to="/">{data.datoCmsSite.globalSeo.siteName}</Link>
              </h6>
              <div
                className="sidebar__intro"
                dangerouslySetInnerHTML={{
                  __html:
                    data.datoCmsHome.introTextNode.childMarkdownRemark.html
                }}
              />
              <ul className="sidebar__menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
              <p className="sidebar__social">
                {data.allDatoCmsSocialProfile.edges.map(({ node: profile }) => (
                  <a
                    key={profile.profileType}
                    href={profile.url}
                    target="blank"
                    className={`social social--${profile.profileType.toLowerCase()}`}
                  >
                    {" "}
                  </a>
                ))}
              </p>
              <div className="sidebar__copyright">
                {data.datoCmsHome.copyright}
              </div>
            </div>
          </div>
          <div className="container__body">
            <div className="container__mobile-header">
              <div className="mobile-header">
                <div className="mobile-header__menu">
                  <button
                    onClick={e => {
                      e.preventDefault();
                      setShowMenu(!showMenu);
                    }}
                  />
                </div>
                <div className="mobile-header__logo">
                  <Link to="/">{data.datoCmsSite.globalSeo.siteName}</Link>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/
